import PropTypes from "prop-types";
import { Link } from "gatsby";
import React from "react";
import TwemojiComponent from "../core/twemoji";
const moment = require(`moment`);

const BlogPostByCategoryComponent = ({ category, posts }) => {
    return (
      <>
        <h2 id={category.slug} className="text-3xl font-bold mb-1 first:mt-0 mt-8 tracking-tight"><Link to={`/Blog/Category/${category.slug}`}>{category.name} ({posts.length})</Link></h2>
        <div className="border-gray-200 dark:border-gray-700">
          <ul className="divide-y divide-gray-200 dark:divide-gray-700">
              {posts.map(({ node }) => {
                return (
                  <li key={node.fields.slug} className="py-4">
                      <article>
                          <div className="space-y-2 xl:space-y-0 xl:items-baseline">
                            <div className="space-y-5">
                              <div>
                                  <div>
                                    <div>
                                        <dt className="sr-only">Published on</dt>
                                        <dd className="flex text-base font-medium leading-6 text-gray-500 dark:text-gray-400">
                                            <time dateTime={moment(node.frontmatter.date).format("YYYY-MM-DDThh:mm:ssTZD")} className="flex items-center">
                                                <TwemojiComponent emoji="calendar" size="" />
                                                <span className="ml-1.5">
                                                    {moment(node.frontmatter.date).format("MMM DD, YYYY")}
                                                </span>
                                            </time>
                                            <span className="mx-2">-</span>
                                            <div className="flex items-center">
                                                <TwemojiComponent emoji="hourglass-not-done" size="" />
                                                <span className="ml-1.5">{node.timeToRead} min read</span>
                                            </div>
                                        </dd>
                                    </div>
                                    <h3 className="text-2xl font-bold mb-1 tracking-tight break-words"> 
                                        <Link
                                          to={node.fields.slug}
                                          className="text-gray-900 dark:text-gray-100">
                                            {node.frontmatter.title}
                                        </Link>
                                    </h3>
                                  </div>
                                  <div className="prose text-gray-500 max-w-none dark:text-gray-400">
                                      {node.frontmatter.summary}
                                  </div>
                              </div>
                            </div>
                          </div>
                      </article>
                  </li>
                )
              })}
          </ul>
        </div>
      </>
  )
}

BlogPostByCategoryComponent.propTypes = {
  category: PropTypes.object,
  posts: PropTypes.array
}

export default BlogPostByCategoryComponent